@import "../../../../variables.scss";

.details {
    display: flex;
    gap: 3em;
    padding-top: 2em;
}

.list {
    overflow-y: auto;
    width: 100%;
    max-width: 250px;
    max-height: 60vh;
    ul li div {
        div {
            &:nth-child(1) {
                min-width: 1.875rem;
                span {
                    font-size: 1rem;
                }
            }
            &:nth-child(2) {
                span {
                    font-size: 0.875rem;
                }
            }
        }
        svg {
            font-size: 1rem;
        }
    }
}

.main {
    width: 100%;
    overflow: hidden;
    max-width: 100%;
    flex-grow: 1;
    form {
        label {
            font-size: 0.875rem;
        }
    }
}
